import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
// import Section from '../components/Section';
import CTA from '../components/CTA';
import BlogSection from '../components/BlogSection';

import { Box, Content, Container, Columns, Column } from '../components/bulma';

export const PricingPageTemplate = ({
	image,
	title,
	heading,
	description,
	intro,
	main,
	testimonials,
	fullImage,
	pricing
}) => (
	<div className="content">
		<br />
		<Hero
			title="Choose a plan which suits you best."
			subtitle="All plans include next day funding and 24/7 payment support as a standard. Cancel free any time."
			image="image"
			// cta
		/>

		<section>
			<Container>
				<Columns>
					<Column>
						<Box>
							<h2 className="title is-size-2">Small</h2>
							<h3 className="subtitle is-size-6">
								Free, when your sales are from $50,000 per month.
								<br />Otherwise, a monthly fee of $49.
							</h3>
							<p className="subtitle">Interchange plus</p>
							<p className="title">0.25% and 25¢</p>
							<ul className="has-text-weight-bold">
								<li>All Payment Features</li>
								<li>Free Mobile App</li>
								<li>No Monthly Fees*</li>
								<li>Next Day Funding</li>
								<li>Cancel Anytime</li>
							</ul>
							<hr />
							<a href="https://app.payment.ninja/signup" className="button is-primary">
								<strong>GET STARTED</strong>
							</a>
						</Box>
					</Column>
					<Column>
						<Box>
							<h2 className="title is-size-1">Medium</h2>
							<h3 className="subtitle is-size-6">
								Free when your sales are from $100,000 per month.
								<br />Otherwise, a monthly fee of $120.
							</h3>
							<p className="subtitle">Interchange plus</p>
							<p className="title">0.15% and 15¢</p>
							<ul className="has-text-weight-bold">
								<li>All Payment Features</li>
								<li>Free Mobile App</li>
								<li>No Monthly Fees*</li>
								<li>Next Day Funding</li>
								<li>Cancel Anytime</li>
							</ul>
							<hr />
							<a href="https://app.payment.ninja/signup" className="button is-primary">
								<strong>GET STARTED</strong>
							</a>
						</Box>
					</Column>
					<Column>
						<Box>
							<h2 className="title is-size-1">Large</h2>
							<h3 className="subtitle is-size-6">
								Free when your sales are from $1000,000 per month.
								<br />Otherwise, a monthly fee of $499.
							</h3>
							<p className="subtitle">Interchange plus</p>
							<p className="title">0.05% and 5¢</p>
							<ul className="has-text-weight-bold">
								<li>All Payment Features</li>
								<li>Free Mobile App</li>
								<li>No Monthly Fees*</li>
								<li>Next Day Funding</li>
								<li>Cancel Anytime</li>
							</ul>
							<hr />
							<a href="https://app.payment.ninja/signup" className="button is-primary">
								<strong>GET STARTED</strong>
							</a>
						</Box>
					</Column>
					{/* <Column>
						<Box>
							<h2 className="title is-size-1">Large</h2>
							<h3 className="subtitle is-size-6">
								Free when your sales are from $100,000 per month.
								<br />Otherwise, a monthly fee of $120.
							</h3>
							<p className="subtitle">Interchange plus</p>
							<p className="title">0.15% and 15¢</p>
							<ul className="has-text-weight-bold">
								<li>All Payment Features</li>
								<li>Free Mobile App</li>
								<li>No Monthly Fees*</li>
								<li>Next Day Funding</li>
								<li>Cancel Anytime</li>
							</ul>
							<hr />
							<a href="https://app.payment.ninja/signup" className="button is-primary">
								<strong>GET STARTED</strong>
							</a>
						</Box>
					</Column> */}
				</Columns>
			</Container>
		</section>
		<br />
		<br />
		<br />
		<br />

		{/* <BlogSection title="Get more out of Ruby" /> */}
		<CTA />
	</div>
);

PricingPageTemplate.propTypes = {
	image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
	title: PropTypes.string,
	heading: PropTypes.string,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array
	}),
	main: PropTypes.shape({
		heading: PropTypes.string,
		description: PropTypes.string,
		image1: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
		image2: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
		image3: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ])
	}),
	testimonials: PropTypes.array,
	fullImage: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
	pricing: PropTypes.shape({
		heading: PropTypes.string,
		description: PropTypes.string,
		plans: PropTypes.array
	})
};

const PricingPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<PricingPageTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				description={frontmatter.description}
				intro={frontmatter.intro}
				main={frontmatter.main}
				testimonials={frontmatter.testimonials}
				fullImage={frontmatter.full_image}
				pricing={frontmatter.pricing}
			/>
		</Layout>
	);
};

PricingPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object
		})
	})
};

export default PricingPage;

export const pricingPageQuery = graphql`
	query PricingPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				heading
				description
				intro {
					blurbs {
						image {
							childImageSharp {
								fluid(maxWidth: 240, quality: 64) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
						text
					}
					heading
					description
				}
				main {
					heading
					description
					image1 {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 526, quality: 92) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
					image2 {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 526, quality: 92) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
					image3 {
						alt
						image {
							childImageSharp {
								fluid(maxWidth: 1075, quality: 72) {
									...GatsbyImageSharpFluid_withWebp_tracedSVG
								}
							}
						}
					}
				}
				testimonials {
					author
					quote
				}
				full_image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
					}
				}
				pricing {
					heading
					description
					plans {
						description
						items
						plan
						price
					}
				}
			}
		}
	}
`;
